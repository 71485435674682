/** @jsx jsx */
import { Container, jsx, Divider } from 'theme-ui';
import * as React from 'react';
import { DefaultLayout } from '../components/DefaultLayout';
import { GatsbyHelmet } from '../utils/helmet';
import { useGtag } from '../utils/utils';

const ContactOptionsData: object[] = [
  {
    type: 'twitter',
    text: '@ChrisWolmarans',
    url: 'https://twitter.com/chriswolmarans',
  },
  {
    type: 'github',
    text: '@chriswolmarans',
    url: 'https://github.com/chriswolmarans',
  },
  {
    type: 'linkedin',
    text: 'linkedin.com/in/chris-wolmarans',
    url: 'https://www.linkedin.com/in/chris-wolmarans/',
  },
];

const ContactPage: React.FC = () => {
  return (
    <DefaultLayout>
      <GatsbyHelmet title="Contact"/>
      <h6 sx={{variant: 'link.breadcrumbHeading'}}>
        say hello.
       <Divider sx={{variant: 'link.breadcrumbDivider'}}/>
      </h6>
      <Container sx={{variant: 'workProjectContainer'}}>
        <h1 sx={{ fontWeight: '400', color: 'primary' }}>
          Find out how I can help you today.
        </h1>
        <h3 sx={{fontWeight: 'medium'}}>Let's talk about your next project.</h3>
        <p>To discuss availability, rates or start dates, connect with me on:</p>
        <ul sx={{variant: 'contactListContainer'}}>
          {ContactOptionsData.map((item: any) => (
            <li sx={{margin: '0 0 25px'}} key={item.text}>
              <p sx={{ margin: '0', color: 'textMuted'}}>{item.type}</p>
              <a sx={{variant: 'link.heading'}}
                 onClick={() => useGtag('event','click','menu',{ name: `${item.type}-${item.url}` })}
                 href={item.url}>{item.text}</a>
            </li>
          ))}
        </ul>
      </Container>
    </DefaultLayout>
  );
};

export default ContactPage;
